var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"position-relative full-height ma-0 pa-0",staticStyle:{"overflow":"hidden"}},[(_vm.loading)?_c('v-progress-circular',{staticClass:"position-absolute center",attrs:{"color":"primary","indeterminate":"","size":"150","width":"10"}}):_vm._e(),(_vm.tasks && !_vm.error && _vm.hasFit)?_c('div',{staticClass:"position-absolute task-preview-tile",on:{"click":function($event){_vm.canvasFocus = false},"mouseover":function($event){_vm.canvasFocus = false},"focus":function($event){_vm.canvasFocus = false}}},[_c('PreviewTile',{attrs:{"options":_vm.searchOptions,"tasks":_vm.tasks},on:{"clear-task":function($event){return _vm.handleSelect({ id: _vm.selectedTaskId })},"select-task":_vm.handleSelect}})],1):_vm._e(),(!_vm.error)?_c('canvas',{style:(_vm.canvasStyle),attrs:{"id":_vm.id},on:{"click":_vm.handleCanvasClick,"mousemove":_vm.handleCanvasMove}}):_vm._e(),(_vm.showCards && _vm.transform && _vm.showDetails.level1 && !_vm.error)?_c('div',{staticClass:"node-data-group position-absolute",style:(_vm.nodeDataGroupTranslate)},_vm._l((_vm.visibleNodes),function(data){return _c('SchematicNode',{key:data.id,attrs:{"disabled":_vm.selectedTaskId && _vm.selectedTaskId !== data.id,"multiplier":_vm.size,"node-data":data,"show-details":_vm.showDetails.level2,"size":_vm.size,"transform":_vm.transform},on:{"mouseover":function($event){_vm.updateTooltip()
        _vm.canvasFocus = true},"mouseout":_vm.updateTooltip,"node-click":_vm.handleSelect}})}),1):_vm._e(),(_vm.tooltipData && _vm.showTooltip && _vm.transform)?_c('Tooltip',{style:(_vm.tooltipStyle(_vm.tooltipData)),attrs:{"data":_vm.tooltipData.data}}):_vm._e(),(!_vm.hideControls && !_vm.error && _vm.hasFit)?_c('v-toolbar',{staticClass:"toolbar px-0 mx-0",attrs:{"dense":"","elevation":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tile":""},on:{"click":_vm.fitViz}},on),[_c('v-icon',[_vm._v("center_focus_strong")])],1)]}}],null,false,3353426983)},[_c('span',[_vm._v(" Reset Viewport ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tile":""},on:{"click":_vm._zoomIn}},on),[_c('v-icon',[_vm._v("zoom_in")])],1)]}}],null,false,2894559917)},[_c('span',[_vm._v(" Zoom In ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tile":""},on:{"click":_vm._zoomOut}},on),[_c('v-icon',[_vm._v("zoom_out")])],1)]}}],null,false,2727217741)},[_c('span',[_vm._v(" Zoom Out ")])])],1):_vm._e(),(_vm.showLegend && !_vm.error)?_c('Legend',{staticClass:"position-absolute legend",attrs:{"tasks":_vm.tasks}}):_vm._e(),(_vm.error)?_c('v-card',{staticClass:"position-absolute center",attrs:{"tile":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('transition',{attrs:{"name":"fade-expand","mode":"out-in"}},[(!_vm.errorSubmitted && !_vm.errorReportLoading)?_c('div',{key:"step1"},[_c('div',{staticClass:"text-h4"},[_vm._v(" Sorry, we're unable to load this schematic. ")]),_c('div',{staticClass:"mt-2"},[_vm._v("Help us improve by submitting a report.")])]):(_vm.errorReportLoading)?_c('div',{key:"step2",staticClass:"text-h4"},[_vm._v(" Submitting report... ")]):_c('div',{key:"step2"},[_c('div',{staticClass:"text-h4 mb-2"},[_vm._v(" Thanks! We'll get our team on it immediately. ")]),_vm._v(" By the way, you can "),_c('a',{attrs:{"target":"_blank","href":"https://prefect.io/slack"}},[_vm._v("join our Slack")]),_vm._v(" to ask questions, provide feedback, or just to chat! You can also check out our "),_c('a',{attrs:{"target":"_blank","href":"https://docs.prefect.io"}},[_vm._v("docs")]),_vm._v(", which are filled with lots of helpful tutorials, explanations, and useful information. ")])])],1),_c('v-card-actions',[(!_vm.errorSubmitted)?_c('v-btn',{staticClass:"ma-auto",attrs:{"depressed":"","color":"warning","loading":_vm.errorReportLoading},on:{"click":_vm.submitReport}},[_vm._v(" Submit Report ")]):_c('v-btn',{staticClass:"ma-auto",attrs:{"color":"success","ripple":false,"depressed":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("check")]),_vm._v(" Report submitted ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }