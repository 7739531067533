var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.task)?_c('v-sheet',{attrs:{"color":"appBackground"}},[_c('SubPageNav',{attrs:{"icon":"pi-task","page-type":"Task"}},[_c('span',{style:(_vm.loading !== 0
          ? {
              display: 'block',
              height: '28px',
              overflow: 'hidden',
              width: '400px'
            }
          : _vm.$vuetify.breakpoint.smAndDown && {
              display: 'inline'
            }),attrs:{"slot":"page-title"},slot:"page-title"},[(_vm.loading === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.task.name)+" ")]):_c('span',{staticStyle:{"width":"500px"}},[_c('v-skeleton-loader',{attrs:{"type":"heading","tile":""}})],1)]),_c('span',{style:(_vm.loading !== 0
          ? {
              display: 'block',
              height: '21px',
              overflow: 'hidden',
              width: '500px'
            }
          : _vm.$vuetify.breakpoint.smAndDown && {
              display: 'inline',
              'font-size': '0.875rem'
            }),attrs:{"slot":"breadcrumbs"},slot:"breadcrumbs"},[(_vm.task && _vm.loading === 0)?_c('BreadCrumbs',{attrs:{"crumbs":[
          {
            route: {
              name: 'project',
              params: { id: _vm.task.flow.project.id }
            },
            text: _vm.task.flow.project.name
          },
          {
            route: {
              name: 'flow',
              params: { id: _vm.task.flow.id }
            },
            text: _vm.task.flow.name
          }
        ]}}):_c('v-skeleton-loader',{attrs:{"type":"text"}})],1),_c('span',{staticStyle:{"width":"100%"},attrs:{"slot":"tabs"},slot:"tabs"},[_c('NavTabBar',{attrs:{"tabs":_vm.tabs,"page":"flow"}})],1)]),_c('v-tabs-items',{staticClass:"px-6 mx-auto tabs-border-bottom tab-full-height",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'padding-top: 130px' : 'padding-top: 80px'),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"runs","transition":"quick-fade","reverse-transition":"quick-fade"}},[_c('TileLayoutFull',[_c('TaskRunTableTile',{attrs:{"slot":"row-2-tile","task-id":_vm.taskId,"loading":_vm.loading > 0},slot:"row-2-tile"})],1)],1),_c('v-tab-item',{staticClass:"pa-0",attrs:{"value":"overview","transition":"quick-fade","reverse-transition":"quick-fade"}},[_c('TileLayout',[_c('DetailsTile',{attrs:{"slot":"row-2-col-1-row-1-tile-1","task":_vm.task,"last-run":_vm.lastTaskRun,"loading":_vm.loading > 0},slot:"row-2-col-1-row-1-tile-1"}),_c('TaskRunHeartbeatTile',{attrs:{"slot":"row-2-col-1-row-2-tile-1","task-id":_vm.taskId},slot:"row-2-col-1-row-2-tile-1"}),_c('DependenciesTile',{attrs:{"slot":"row-2-col-2-row-3-tile-1","downstream-count":_vm.downstreamCount,"loading":_vm.loading > 0,"flow":_vm.task ? _vm.task.flow : null,"tasks":_vm.dependencies,"upstream-count":_vm.upstreamCount},slot:"row-2-col-2-row-3-tile-1"})],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-bottom-navigation',{attrs:{"app":"","fixed":""}},[_c('v-btn',{on:{"click":function($event){_vm.tab = 'overview'}}},[_vm._v(" Overview "),_c('v-icon',[_vm._v("view_module")])],1),_c('v-btn',{on:{"click":function($event){_vm.tab = 'runs'}}},[_vm._v(" Runs "),_c('v-icon',[_vm._v("pi-task-run")])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }